import "./index.css"
import { Provider } from 'react-redux';
// import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import store from './store'
import CNLogo from "./components/CNLogo";
// import Navbar from "./components/Header/Navbar";
// import TempCard from "./components/Cards/TempCard";

function App() {
  return (
    <Provider store={store}>
      {/* <Router>
        <div className="h-screen bg-gradient-to-r from-indigo-500 to-blue-300">
          <Navbar />
          <Routes>
            <Route path="/" element={<TempCard />} />
          </Routes>
        </div>
      </Router> */}
      <div
        class="flex items-center justify-center w-screen h-screen bg-gradient-to-r from-indigo-600 to-blue-400"
      >
        <div class="px-40 py-20 bg-slate-800 rounded-md shadow-xl">
          <div class="flex flex-col items-center">
            <h1 class="font-bold text-white text-2xl mb-4">
              Learn<span className="bg-red-800 rounded px-2 shadow-xl text-white">Tube</span> by</h1>
            <CNLogo />
            <h6 class="my-5 text-2xl font-bold text-center text-white md:text-3xl">
              <span class="text-yellow-500">Hang tight!</span> Work In Progress 🛠️
            </h6>
            <p class="mb-8 text-center text-gray-500 md:text-lg">
              The page you’re looking for is being developed at the speed of light⚡
            </p>
            {/* <a
              href="#"
              class="px-6 py-2 text-sm font-semibold text-blue-800 bg-blue-100"
            >Go home</a
            > */}
          </div>
        </div>
      </div>

    </Provider>
  );
}

export default App;