import { combineReducers } from 'redux';
import boilerplateReducer from './boilerplateReducer';

const appReducer = combineReducers({
    boilerplateReducer,
});

const rootReducer = (state, action) => {
    return appReducer(state, action);
};

export default rootReducer;