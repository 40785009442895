import { GET_SOMETHING, SET_SOMETHING } from '../actions/types';

const initialState = {
    items: [],
    item: {}
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_SOMETHING:
            return {
                ...state,
                items: action.payload
            };
        case GET_SOMETHING:
            return {
                ...state,
                item: action.payload
            };
        default:
            return state;
    }
}